<script lang="ts" setup>
import { computed, useCssModule, useSlots, h, type VNode, type HTMLAttributes } from 'vue';
import type { StatusBasedVariant } from '../../shared/types';
import { isVNodeEmpty } from '../../utils';

interface Props {
  asChild?: boolean;
  clickable?: boolean;
  variant?: StatusBasedVariant;
}

interface SlotScope {
  rootProps: { class: HTMLAttributes['class'] };
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  clickable: false,
  variant: 'default',
});

defineSlots<{
  default?: (props: SlotScope) => VNode;
  leadingVisual?: () => VNode;
  trailingVisual?: () => VNode;
}>();

const style = useCssModule();

const rootProps = computed<SlotScope['rootProps']>(() => ({
  class: [
    style.root,
    {
      [style.clickable]: props.clickable,
      [style.variantInfo]: props.variant === 'info',
      [style.variantWarning]: props.variant === 'warning',
      [style.variantNegative]: props.variant === 'negative',
      [style.variantPositive]: props.variant === 'positive',
    },
  ],
}));

const slots = useSlots();

defineRender(() => {
  const children = slots.default?.({ rootProps: rootProps.value });

  if (props.asChild) {
    return children;
  }

  const leadingVisual = slots.leadingVisual?.();
  const trailingVisual = slots.trailingVisual?.();

  return h(
    'span',
    {
      ...rootProps.value,
      tabindex: props.clickable ? 0 : undefined,
    },
    [
      !isVNodeEmpty(leadingVisual)
        ? h('span', { class: style.leadingVisual }, leadingVisual)
        : null,
      children,
      !isVNodeEmpty(trailingVisual)
        ? h('span', { class: style.trailingVisual }, trailingVisual)
        : null,
    ],
  );
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  box-sizing: border-box;
  border: none;
  outline: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  vertical-align: top;
  color: #35416d;
  text-align: center;
  font-family: typography.$font-family-primary;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 500;
  border-radius: 11px;
  padding: 0 8px;
  height: 22px;
  align-items: center;
  white-space: nowrap;
  background: #f0f1f4; // TODO: design token
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  touch-action: manipulation;
}

.leadingVisual,
.trailingVisual {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 18px;
  min-width: 16px;
  max-width: 18px;
  color: #9aa0b6;
}

.leadingVisual {
  margin-right: 8px;
}

.trailingVisual {
  margin-left: 8px;
}

.variantInfo {
  background: #e6effb; // TODO: design token

  .leadingVisual,
  .trailingVisual {
    color: #085cd9;
  }
}

.variantWarning {
  background: #fff3ed; // TODO: design token

  .leadingVisual,
  .trailingVisual {
    color: #ff824d;
  }
}

.variantNegative {
  background: #fae1e2; // TODO: design token

  .leadingVisual,
  .trailingVisual {
    color: #e42828;
  }
}

.variantPositive {
  background: #e9f8f4; // TODO: design token

  .leadingVisual,
  .trailingVisual {
    color: #25b592;
  }
}

.clickable {
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.clickable.variantInfo {
  color: #085cd9;

  &:hover {
    background: #b5cef4;
  }
}

.clickable.variantNegative {
  color: #e42828;

  &:hover {
    background: #f4a9a9;
  }
}

.clickable.variantWarning {
  color: #ff824d;

  &:hover {
    background: #ffcdb8;
  }
}

.clickable.variantPositive {
  color: #25b592;

  &:hover {
    background: #a8e1d3;
  }
}
</style>
