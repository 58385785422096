import { defineStore } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import { ref, watch } from 'vue';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

const DATABASE_KEY = 'superset';

export const useSupersetStore = defineStore(StoreNames.Superset, () => {
  const visibleMetrics = ref<{ [key: string]: string[] }>({});
  const appliedFilters = ref<{ [key: string]: { [key: number]: string[] | string | null } }>({});
  const sorting = ref<{ [key: string]: { sortBy: string | null; sortOrder: 'asc' | 'desc' } }>({});

  const api = useUiStatesApi();

  const fetching = ref(false);
  const fetched = ref(false);

  async function fetch(force?: boolean) {
    if (fetching.value || (fetched.value && !force)) {
      return;
    }

    fetching.value = true;
    // Fetch persisted state from the server
    const { data } = await api.getUiStates({ key: DATABASE_KEY });
    const persistedState = data.data[0];

    if (persistedState) {
      // TODO: validate and merge data, persist again in case of invalid values
      visibleMetrics.value = persistedState.value.visibleMetrics || {};
      appliedFilters.value = persistedState.value.appliedFilters || {};
      sorting.value = persistedState.value.sorting || {};
    }
    fetching.value = false;
    fetched.value = true;
  }

  async function persist() {
    await api.saveUiState({
      key: DATABASE_KEY,
      value: {
        visibleMetrics: visibleMetrics.value,
        appliedFilters: appliedFilters.value,
        sorting: sorting.value,
      },
    });
  }

  const debouncedPersist = useDebounceFn(persist, 3000);

  const autoPersistEnabled = ref(false);

  watch(
    [visibleMetrics, appliedFilters, sorting],
    () => {
      if (autoPersistEnabled.value) {
        debouncedPersist();
      }
    },
    {
      deep: true,
    },
  );

  watch(fetched, () => {
    autoPersistEnabled.value = true;
  });

  return {
    fetching,
    fetched,
    fetch,
    visibleMetrics,
    appliedFilters,
    sorting,
  };
});
