<script lang="ts" setup>
import {
  ObAbstractDisclosure,
  ObAbstractDisclosureButton,
  ObAbstractDisclosurePanel,
} from '../abstract-disclosure';
import { ObDisclosureButton } from '../disclosure-button';
import { ObTransitionHeight } from '../../transitions';

interface Props {
  expanded?: boolean;
  unmount?: boolean;
}

defineOptions({
  inheritAttrs: false,
});

const { unmount = true } = defineProps<Props>();

defineEmits<{
  'update:expanded': [expanded: boolean];
}>();

const expanded = defineModel<boolean>('expanded', { default: false });
</script>

<template>
  <ObAbstractDisclosure v-model:expanded="expanded">
    <div :class="$style.root" v-bind="$attrs">
      <div :class="$style.head">
        <div :class="$style.headContent">
          <slot name="head" />
        </div>
        <ObAbstractDisclosureButton v-slot="{ rootProps }">
          <ObDisclosureButton v-bind="rootProps" :expanded />
        </ObAbstractDisclosureButton>
      </div>
      <ObTransitionHeight>
        <ObAbstractDisclosurePanel v-slot="{ rootProps }" :unmount>
          <div v-bind="rootProps" :class="$style.body">
            <div :class="$style.bodyContent">
              <slot />
            </div>
          </div>
        </ObAbstractDisclosurePanel>
      </ObTransitionHeight>
    </div>
  </ObAbstractDisclosure>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/elevation';
@use '../../styles/typography';

.root {
  background: colors.$surface-4;
  border-radius: shared.$border-radius-m;
}

.head {
  @include typography.style('body-1');
  display: flex;
  padding: 20px;
  align-items: center;
}

.headContent {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.body {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
    height: 1px;
    background: colors.$surface-16;
  }
}

.bodyContent {
  padding: 20px;
}
</style>
