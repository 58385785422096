<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  watchEffect,
  ref,
  provide,
  useSlots,
  withDirectives,
  vShow,
  useId,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { injectStrict, isVNodeNotEmpty, asArray } from '../../utils';
import { ABSTRACT_DISCLOSURE_CONTEXT, ABSTRACT_DISCLOSURE_PANEL_CONTEXT } from './shared';

interface Props {
  id?: string;
  unmount?: boolean;
}

const { id = useId(), unmount = true } = defineProps<Props>();

const { panelRef, panelId, expanded } = injectStrict(
  ABSTRACT_DISCLOSURE_CONTEXT,
  undefined,
  '<ObAbstractDisclosurePanel /> must be a child of <ObAbstractDisclosure /> component.',
);

onMounted(() => {
  panelId.value = id;
});

onUnmounted(() => {
  panelId.value = null;
});

provide(ABSTRACT_DISCLOSURE_PANEL_CONTEXT, panelId);

const elementRef = ref<HTMLElement | null>(null);

watchEffect(() => {
  panelRef.value = elementRef.value;
});

const slots = useSlots();

defineRender(() => {
  if (unmount && !expanded.value) {
    return null;
  }

  const children = slots.default?.({
    rootProps: {
      id,
      ref: (el: Element | ComponentPublicInstance | null) => {
        elementRef.value = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
      },
    },
  });

  if (isVNodeNotEmpty(children)) {
    if (unmount) {
      return children;
    }

    return asArray(children).map((item) => withDirectives(item, [[vShow, expanded.value]]));
  }

  return null;
});
</script>
