import { createSharedComposable } from '@vueuse/shared';
import { useApi } from '../../../../services/api';
import type {
  InventoryKpis,
  LocationKpis,
  SkuKpis,
  Inventory,
  Location,
  Sku,
  LocationDetails,
  InventoryDetails,
  InventoryReplenishmentStatusSummary,
  InventoryReplenishmentScope,
  InventoryReplenishmentStatusUpdate,
  InventoryInventoriesSummaryData,
  InventoryLocationsSummaryData,
  InventorySkusSummaryData,
} from '../../interfaces';
import type {
  MissingItem,
  PaginatedResponse,
  PaginationQuery,
  TransportRequestOptions,
} from '../../../../services/api';

export const useInventoryApi = createSharedComposable(() => {
  const { transport } = useApi();

  // Inventories

  function getInventoryKpis() {
    return transport.get<InventoryKpis>('/v1/inventories/kpis');
  }

  function getInventories(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Inventory>>('/v1/inventories', {
      query,
    });
  }

  function getInventoriesByIds(ids: string[]) {
    return transport.post<{ data: Array<Inventory | MissingItem> }>('/v1/inventories/by_ids', {
      ids,
    });
  }

  function getInventory(id: string) {
    return transport.get<InventoryDetails>(`/v1/inventories/${id}`);
  }

  // Locations

  function getLocationKpis() {
    return transport.get<LocationKpis>('/v1/locations/kpis');
  }

  function getLocations(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Location>>('/v1/locations', {
      query,
    });
  }

  function getLocationsByIds(ids: string[]) {
    return transport.post<{ data: Array<Location | MissingItem> }>('/v1/locations/by_ids', {
      ids,
    });
  }

  function getLocation(id: string) {
    return transport.get<LocationDetails>(`/v1/locations/${id}`);
  }

  // SKUs

  function getSkuKpis() {
    return transport.get<SkuKpis>('/v1/skus/kpis');
  }

  function getSkus(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Sku>>('/v1/skus', {
      query,
    });
  }

  function getSkuByIds(ids: string[]) {
    return transport.post<{ data: Array<Sku | MissingItem> }>('v1/skus/by_ids', {
      ids,
    });
  }

  // Replenishment

  function updateReplenishmentStatusByUpload(
    file: File,
    scope: InventoryReplenishmentScope,
    onUploadProgress?: TransportRequestOptions['onUploadProgress'],
  ) {
    return transport.post<{
      task_id: string;
    }>(
      '/v1/settings/avoid_replenishments/update/upload',
      { file, scope },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    );
  }

  function updateReplenishmentStatusByQuery(payload: {
    query: string;
    scope: InventoryReplenishmentScope;
    active: boolean;
  }) {
    return transport.post<{
      task_id: string;
    }>('v1/settings/avoid_replenishments/query', payload);
  }

  function getReplenishmentStatusUpdates(
    query?: PaginationQuery & { scope: InventoryReplenishmentScope },
  ) {
    return transport.get<PaginatedResponse<InventoryReplenishmentStatusUpdate>>(
      '/v1/settings/avoid_replenishments/update/status',
      {
        query,
      },
    );
  }

  function getReplenishmentStatusUpdate(id: string) {
    return transport.get<InventoryReplenishmentStatusUpdate>(
      `/v1/settings/avoid_replenishments/update/status/${id}`,
    );
  }

  function cancelReplenishmentStatusUpdate(id: string) {
    return transport.put(`v1/tasks/abort/${id}`, {});
  }

  function downloadReplenishmentStatus(payload: {
    query: string;
    scope: InventoryReplenishmentScope;
  }) {
    return transport.post<{
      task_id: string;
    }>('v1/settings/avoid_replenishments/download', payload);
  }

  function getReplenishmentStatusSummary() {
    // This endpoint supports `scope` query param, but it's useless and just brings issues with types
    return transport.get<InventoryReplenishmentStatusSummary>(
      '/v1/settings/avoid_replenishments/status',
    );
  }

  // Summary

  function getInventoriesSummary(query?: string) {
    return transport.get<InventoryInventoriesSummaryData>(
      '/v1/settings/avoid_replenishments/summary',
      {
        query: {
          scope: 'inventories',
          query,
        },
      },
    );
  }

  function getLocationsSummary(query?: string) {
    return transport.get<InventoryLocationsSummaryData>(
      '/v1/settings/avoid_replenishments/summary',
      {
        query: {
          scope: 'locations',
          query,
        },
      },
    );
  }

  function getSkusSummary(query?: string) {
    return transport.get<InventorySkusSummaryData>('/v1/settings/avoid_replenishments/summary', {
      query: {
        scope: 'skus',
        query,
      },
    });
  }

  return {
    getInventory,
    getInventoryKpis,
    getInventories,
    getInventoriesByIds,
    getLocationKpis,
    getLocations,
    getLocationsByIds,
    getSkuKpis,
    getSkus,
    getSkuByIds,
    getLocation,
    downloadReplenishmentStatus,
    getReplenishmentStatusSummary,
    updateReplenishmentStatusByUpload,
    updateReplenishmentStatusByQuery,
    getReplenishmentStatusUpdates,
    getReplenishmentStatusUpdate,
    cancelReplenishmentStatusUpdate,
    getInventoriesSummary,
    getLocationsSummary,
    getSkusSummary,
  };
});
