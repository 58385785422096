import { Comment, Text, Slot, Fragment, type VNode } from 'vue';
import { asArray } from '../as-array';

// TODO: drop when official solution will be implemented
// https://github.com/vuejs/core/issues/4733
// https://github.com/vuejs/rfcs/discussions/453

export function isVNodeNotEmpty(
  vnode: VNode | VNode[] | undefined | null,
): vnode is VNode | VNode[] {
  return (
    vnode !== undefined &&
    vnode !== null &&
    asArray(vnode).some(
      (vnode) =>
        vnode.type !== Comment &&
        (vnode.type !== Text || vnode.children?.length) &&
        (vnode.type !== Fragment || vnode.children?.length),
    )
  );
}

export function isVNodeEmpty(vnode: VNode | VNode[] | undefined | null): vnode is VNode | VNode[] {
  return !isVNodeNotEmpty(vnode);
}

export function isSlotEmpty(slot: Slot | undefined | null, props: any = {}): boolean {
  return isVNodeEmpty(slot?.(props));
}

export function hasSlotContent(slot: Slot | undefined | null, props: any = {}): boolean {
  return !isSlotEmpty(slot, props);
}
