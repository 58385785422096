<script lang="ts" setup>
import { computed, useSlots, useCssModule, h, type VNode } from 'vue';
import type { AnchorTargetAttribute } from '../../shared/types';

interface Props {
  asChild?: boolean;
  disabled?: boolean;
  href?: string;
  rel?: string;
  target?: AnchorTargetAttribute;
}

interface SlotScope {
  rootProps: any; // TODO: type for slot scope
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  disabled: false,
  href: undefined,
  rel: undefined,
  target: undefined,
});

defineSlots<{
  default?: (props: SlotScope) => VNode;
}>();

const style = useCssModule();

const rootProps = computed<SlotScope['rootProps']>(() => ({
  href: props.disabled ? undefined : props.href,
  rel: props.rel,
  target: props.target,
  class: [
    style.root,
    {
      [style.disabled]: props.disabled,
    },
  ],
}));

const slots = useSlots();

defineRender(() => {
  const children = slots.default?.({ rootProps: rootProps.value });

  return props.asChild
    ? children
    : h(
        'a',
        {
          ...rootProps.value,
        },
        children,
      );
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
  appearance: none;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  font-family: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: typography.$font-family-primary;
  color: colors.$hyperlink;
  text-decoration: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: 2px;
  }

  &:hover:enabled,
  &:hover:not(.disabled) {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.1em;
  }
}

.disabled {
  cursor: not-allowed;
  color: colors.$hyperlink-40;
}
</style>
