<script setup lang="ts">
import { onBeforeMount, ref, computed, watch } from 'vue';
import { useRoute, useRouter, RouterView } from 'vue-router';
import { until } from '@vueuse/core';
import { useHead } from '@unhead/vue';
import { captureException } from '@sentry/vue';
import { parseISO } from 'date-fns';
import catError from '../assets/cat-table-error.svg';
import { useApi } from '../services/api';
import type { AuthStateChangeEvent } from '../services/api';
import { useHeap } from '../services/heap';
import { setI18nLanguage, LOCALES, DEFAULT_LOCALE, useI18n } from '../services/i18n';
import { useFlagsStatus, useUnleashClient } from '../services/unleash';
import { log } from '../shared/utils';
import {
  useUserStore,
  useDataStore,
  useI18nStore,
  useAccountStore,
  useSystemServicesStatusStore,
} from '../stores';
import { runGuards } from '../router';
import {
  ObConfigProvider,
  ObToasts,
  ObSpinner,
  ObConfirmDialog,
  ObErrorState,
  ObButton,
} from '../ui-kit';
import type { Config } from '../ui-kit';
import { useSupersetKeyMetaStore } from '../modules/superset';
import { useAppReady } from './use-app-ready';
import { useSW } from './use-sw';

useSW();

const router = useRouter();
const route = useRoute();
const { announceAppReady } = useAppReady();
const userStore = useUserStore();
const accountStore = useAccountStore();
const dataStore = useDataStore();
const i18nStore = useI18nStore();
const systemServicesStatusStore = useSystemServicesStatusStore();
const { t, n, d, locale } = useI18n();
const api = useApi();
const unleashClient = useUnleashClient();
const { flagsReady } = useFlagsStatus();
const keyMetaStore = useSupersetKeyMetaStore();
const heap = useHeap();

const initializing = ref(true);
const error = ref(false);

async function reRunCurrentRouteGuards() {
  const result = await runGuards(route, route, { api });

  if (result === false) {
    await router.push('/');
  } else if (typeof result !== 'undefined' && result !== true) {
    await router.push(result);
  }
}

async function handleAuthStateChange(
  event: AuthStateChangeEvent,
  initial?: boolean,
): Promise<void> {
  if (event.authenticated) {
    await Promise.all([userStore.fetch(), accountStore.fetch(), systemServicesStatusStore.fetch()]);

    dataStore.fetch().catch((error) => {
      // TODO: Handle error
      console.error(error);
    });

    heap.identify(userStore.email);
    heap.addUserProperties({
      name: userStore.name,
      email: userStore.email,
      account_id: userStore.account_id,
      account_name: accountStore.name,
    });

    unleashClient.setContextField('userId', userStore.id);
    unleashClient.setContextField('accountId', userStore.account_id);
  } else {
    userStore.clear();
    accountStore.clear();
    dataStore.clear();

    heap.resetIdentity();

    unleashClient.setContextField('userId', '');
    unleashClient.setContextField('accountId', '');
  }

  // Simply check if user can stay on current route
  if (!initial) {
    reRunCurrentRouteGuards();
    // TODO: handle possible error
  }
}

const uiConfig = computed<Config>(() => {
  const config: Config = {
    numberFormat: {
      decimalSeparator: i18nStore.decimalSeparator,
      thousandSeparator: i18nStore.thousandSeparator,
    },
    i18n: {
      BatchActionsMenu: {
        labelSelectedItems(count) {
          return t('common.messages.itemsSelected', count);
        },
      },
      FileUploader: {
        labelTrigger: t('ui.fileUploader.labelTrigger'),
      },
      TablePagination: {
        labelRowsPerPage: t('ui.TablePagination.labelRowsPerPage'),
        labelDisplayedRows(currentPageStart, currentPageEnd, total) {
          return t('ui.TablePagination.labelDisplayedRows', {
            min: n(currentPageStart, 'pretty'),
            max: n(currentPageEnd, 'pretty'),
            total: n(total, 'pretty'),
          });
        },
      },
      DatePicker: {
        label(value, selectionMode) {
          if (selectionMode === 'range') {
            const { start, end } = value as any; // TODO: fix type issue

            if (start && end) {
              return `${d(parseISO(start), 'date')} - ${d(parseISO(end), 'date')}`;
            }

            if (start) {
              return `${d(parseISO(start), 'date')} ...`;
            }

            return 'Select dates';
          }

          if (typeof value === 'string') {
            return `${d(parseISO(value), 'date')}`;
          }

          return 'Select date';
        },
      },
    },
  };

  return config;
});

onBeforeMount(async () => {
  log('initializing app');

  try {
    log('waiting for auth module init');
    // Wait auth
    await api.auth.init();

    log('auth module initialized');

    // Handle initial auth state manually
    await handleAuthStateChange({ authenticated: api.auth.isAuthenticated() }, true);

    api.auth.onStateChanged((event) => handleAuthStateChange(event, false));

    // Use previously selected locale (in future can be saved in user profile) or account default locale or browser locale...
    // ...but only if locale is supported by the app.

    log('setting language');

    let locale = i18nStore.locale || accountStore.locale || window.navigator.language;

    if (!LOCALES.find(({ code }) => code === locale)) {
      locale = DEFAULT_LOCALE;
    }

    setI18nLanguage(locale);
    i18nStore.setLocale(locale);

    log('fetching superset meta store');

    await keyMetaStore.fetch();

    log('starting unleash client');

    await unleashClient.start();

    await until(flagsReady).toBe(true);

    // TODO: throw error if flagsError or just ignore (report to sentry) and work without flags?

    announceAppReady();

    log('waiting for router initialization');

    // Wait initial router navigation
    await router.isReady();

    // Check if user can stay on current route after feature flags update
    unleashClient.on('update', () => {
      reRunCurrentRouteGuards();
    });
    log('app is ready');
  } catch (err) {
    captureException(err);
    error.value = true;
    log('app initialization failed');
  } finally {
    initializing.value = false;
  }
});

// TODO: handle race condition
watch(locale, () => {
  keyMetaStore.fetch();
});

useHead(
  computed(() => ({
    htmlAttrs: {
      lang: i18nStore.locale,
      dir: i18nStore.textDirection,
    },
    titleTemplate: (value) => `${value ? value + ' | ' : ''}Onebeat App`,
  })),
);

function reloadApp() {
  window.location.reload();
}
</script>

<template>
  <ObConfigProvider :config="uiConfig">
    <div v-if="initializing" class="p-8">
      <ObSpinner />
    </div>
    <ObErrorState v-else-if="error" style="min-height: 100vh">
      <template #image>
        <img :src="catError" alt="" />
      </template>
      {{ t('app.messages.internalServerError') }}
      <template #details>
        <ObButton @click="reloadApp()">{{ t('app.actions.reload') }}</ObButton>
      </template>
    </ObErrorState>
    <RouterView v-else />
    <ObToasts />
    <ObConfirmDialog />
  </ObConfigProvider>
</template>

<style>
body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  margin: 0;
  min-width: 1400px;
  font-optical-sizing: auto;
}
</style>
