<script lang="ts" setup>
import {
  ref,
  watch,
  useSlots,
  computed,
  nextTick,
  onBeforeUnmount,
  reactive,
  watchEffect,
  type VNode,
  type ComputedRef,
} from 'vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { unrefElement, refAutoReset, useEventListener, onClickOutside } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { isNil } from 'lodash-es';
import { IconX, IconGripVertical } from '@tabler/icons-vue';
import { hasSlotContent, moveFocusInside, pxOrValue } from '../../utils';
import { useZIndex, useStack, useTrueSelfClick, useFocusScope } from '../../composables';
import { Stacks } from '../../shared/enums';
import { ObModalOverlay } from '../modal-overlay';

interface Props {
  active?: boolean;
  ariaLabel?: string;
  closeButton?: boolean;
  deactivateOnEscapePress?: boolean;
  deactivateOnOverlayClick?: boolean;
  deactivateOnClickOutside?: boolean;
  modal?: boolean;
  onBeforeDeactivate?: () => Promise<void | boolean> | void | boolean;
  resizable?: boolean;
  width?: number;
}

defineOptions({
  inheritAttrs: false,
});

const {
  ariaLabel = '',
  closeButton = true,
  deactivateOnEscapePress = true,
  deactivateOnOverlayClick = true,
  deactivateOnClickOutside = false,
  modal = false,
  onBeforeDeactivate,
  resizable = false,
} = defineProps<Props>();

const emit = defineEmits<{
  'update:active': [active: boolean];
  'update:width': [width: number];
  activated: [];
  deactivated: [];
}>();

interface SlotScope {
  active: ComputedRef<boolean>;
  activate: () => void;
  deactivate: () => void;
  toggle: () => void;
}

defineSlots<{
  activator?: (props: SlotScope) => VNode;
  default?: (props: SlotScope) => VNode;
  footer?: (props: SlotScope) => VNode;
  host?: (props: SlotScope) => VNode;
  title?: () => VNode;
}>();

const active = defineModel<boolean>('active', { default: false });

const rootEl = ref();

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(rootEl, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: false,
  returnFocusOnDeactivate: true,
});

let activationTrigger: HTMLElement | null = null;

const transitionActive = ref(false);

watchEffect(() => {
  if (active.value) {
    transitionActive.value = true;
  }
});

const { zIndex } = useZIndex({ active: transitionActive, size: 2 });

const { last: lastWithModalOverlay } = useStack(Stacks.ModalOverlay, {
  active: transitionActive,
});

const { last: lastInSidePages } = useStack(Stacks.SidePages, {
  active: transitionActive,
});

const { active: focusScopeActive } = useFocusScope(rootEl);

const shaking = refAutoReset(false, 1000);

function applyModalLock() {
  activateTrap();
  disableBodyScroll(unrefElement(rootEl), {
    reserveScrollBarGap: true,
  });
}

function removeModalLock() {
  deactivateTrap();
  enableBodyScroll(unrefElement(rootEl));
}

function activate() {
  activationTrigger = document.activeElement as HTMLElement;
  active.value = true;
  emit('activated');

  nextTick(() => {
    if (!active.value) {
      return;
    }

    if (modal) {
      applyModalLock();
    } else {
      moveFocusInside(unrefElement(rootEl));
    }
  });
}

function deactivate() {
  active.value = false;
  emit('deactivated');

  if (modal) {
    removeModalLock();
    return;
  }

  if (activationTrigger) {
    activationTrigger.focus({ preventScroll: true });
    activationTrigger = null;
  }
}

function toggle() {
  if (active.value) {
    deactivate();
    return;
  }

  activate();
}

async function tryDeactivate() {
  if (typeof onBeforeDeactivate === 'function') {
    const allowed = await onBeforeDeactivate();

    if (allowed === false) {
      shaking.value = true;
      return;
    }
  }

  deactivate();
}

function onEscapePress() {
  if (!deactivateOnEscapePress) {
    return;
  }
  tryDeactivate();
}

onClickOutside(rootEl, () => {
  if (deactivateOnClickOutside && !focusScopeActive.value) {
    deactivate();
  }
});

watch(
  active,
  (value) => {
    if (value) {
      activate();
      return;
    }

    deactivate();
  },
  {
    immediate: true,
  },
);

watch(
  () => modal,
  (val) => {
    if (!active.value) {
      return;
    }

    if (val) {
      applyModalLock();
      return;
    }

    removeModalLock();
  },
);

const overlayEl = ref();

useTrueSelfClick(overlayEl, () => {
  if (!deactivateOnOverlayClick) {
    return;
  }
  tryDeactivate();
});

const bodyScrollEl = ref();

defineExpose({
  bodyScrollEl,
});

/* Resize */

const boxEl = ref();

const width = defineModel<number>('width', { default: 680 });
const currentWidth = ref(width.value);

const pointer = reactive({ startX: 0, currentX: 0 });
const resizing = ref(false);

function onPointerDown(event: PointerEvent) {
  if (!resizable) {
    return;
  }

  event.preventDefault();

  currentWidth.value = width.value;
  pointer.startX = event.x;
  pointer.currentX = event.x;

  resizing.value = true;
}

function onPointerUp(event: PointerEvent) {
  if (!resizing.value) {
    return;
  }

  event.preventDefault();
  resizing.value = false;

  // TODO: is it a good solution? It allows to set min/max width in css, use %/vw.
  const realWidth = parseInt(window.getComputedStyle(boxEl.value).width, 10);
  currentWidth.value = Math.round(realWidth);
  width.value = currentWidth.value;
}

function onPointerMove(event: PointerEvent) {
  if (!resizing.value) {
    return;
  }

  pointer.currentX = event.x;

  const diff = pointer.startX - pointer.currentX;

  currentWidth.value = width.value + diff;
}

useEventListener(window, 'pointerup', onPointerUp);
useEventListener(window, 'pointercancel', onPointerUp);
useEventListener(window, 'lostpointercapture', onPointerUp);
useEventListener(window, 'pointermove', onPointerMove);

onBeforeUnmount(() => {
  resizing.value = false;
});

/* Render */

const slots = useSlots();
const hasTitle = computed(() => hasSlotContent(slots.title));
const hasHeader = computed(() => closeButton || hasTitle.value);
const hasFooter = computed(() => hasSlotContent(slots.footer));

const boxStyles = computed(() => ({
  width: !isNil(currentWidth.value) ? pxOrValue(currentWidth.value) : undefined,
}));

const slotScope: SlotScope = { active: computed(() => active.value), activate, deactivate, toggle };
</script>

<template>
  <slot name="activator" v-bind="slotScope" />
  <Teleport v-if="transitionActive" to="body">
    <Transition
      :enter-from-class="$style.enterFrom"
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.leaveActive"
      :leave-to-class="$style.leaveTo"
      appear
      @after-leave="transitionActive = false"
    >
      <div
        v-if="active"
        ref="rootEl"
        :class="[$style.root, { [$style.shake]: shaking, [$style.pull]: !lastInSidePages }]"
        role="dialog"
        :aria-modal="modal"
        :aria-label="ariaLabel"
        :style="{ zIndex }"
        tabindex="-1"
        @keydown.esc.stop="onEscapePress"
      >
        <div ref="boxEl" v-bind="$attrs" :class="$style.box" :style="boxStyles">
          <div v-if="resizable" :class="$style.handle" @pointerdown="onPointerDown">
            <IconGripVertical :size="16" />
          </div>
          <header v-if="hasHeader" :class="$style.header">
            <div v-if="hasTitle" :class="$style.title">
              <slot name="title" />
            </div>
            <button
              v-if="closeButton"
              type="button"
              :class="$style.close"
              aria-label="Close"
              @click="tryDeactivate()"
            >
              <IconX aria-hidden="true" />
            </button>
          </header>
          <div ref="bodyScrollEl" :class="$style.body">
            <slot v-bind="slotScope" />
          </div>
          <footer v-if="hasFooter" :class="$style.footer">
            <slot name="footer" v-bind="slotScope" />
          </footer>
        </div>
      </div>
    </Transition>
    <ObModalOverlay
      v-if="modal && lastWithModalOverlay"
      ref="overlayEl"
      :z-index="zIndex ? zIndex - 1 : undefined"
    />
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  position: fixed;
  right: 0;
  top: 8px;
  bottom: 8px;
  will-change: transform;
  outline: none;
  width: 100%;
  pointer-events: none;
}

.box {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 240px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  background: colors.$white;
  pointer-events: auto;
  transition: transform 0.1s ease-in-out;
  box-shadow: 0px 0px 18px rgba(2, 17, 72, 0.2); // TODO: use token
  border-radius: shared.$border-radius-m 0 0 shared.$border-radius-m;
  font-family: typography.$font-family-primary;
}

.header {
  flex: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 24px;
}

.title {
  font-size: 18px;
  line-height: 24px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.close {
  @include shared.reset-button();
  flex: none;
  margin-left: 8px;
  display: flex;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.body {
  @include shared.scrollbar();
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow-y: scroll;
  padding: 24px;
  overscroll-behavior: contain;

  &:not(:last-child) {
    padding-bottom: 0;
  }
}

.header + .body {
  padding-top: 0;
}

.footer {
  flex: none;
  padding: 24px;
}

.handle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 16px;
  display: flex;
  align-items: center;
  color: colors.$primary;
  cursor: ew-resize;

  &:hover {
    background-color: colors.$surface-4;
  }
}

.enterActive,
.leave-active {
  transition-property: transform;
}

.enterActive {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  transform: translateX(50%);
}

.shake {
  animation: shake 1s linear 0.2s infinite;
}

.pull .box {
  transform: translateX(-8px);
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(0, 0, 0);
  }
}
</style>
