<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  watchEffect,
  computed,
  inject,
  useSlots,
  ref,
  useId,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { injectStrict } from '../../utils';
import { Keys } from '../../shared/enums';
import { ABSTRACT_DISCLOSURE_CONTEXT, ABSTRACT_DISCLOSURE_PANEL_CONTEXT } from './shared';

interface Props {
  disabled?: boolean;
  id?: string;
}

const { id = useId(), disabled = false } = defineProps<Props>();

const context = injectStrict(
  ABSTRACT_DISCLOSURE_CONTEXT,
  undefined,
  '<ObAbstractDisclosureButton /> must be a child of <ObAbstractDisclosure /> component.',
);
const panelContext = inject(ABSTRACT_DISCLOSURE_PANEL_CONTEXT, null);

const withinPanel = computed(() =>
  panelContext === null ? false : panelContext?.value === context.panelId.value,
);

const elementRef = ref<HTMLElement | null>(null);

function toggleDisclosure() {
  context.toggle();

  if (withinPanel.value) {
    unrefElement(context.buttonRef)?.focus();
  }
}

function onClick() {
  if (disabled) {
    return;
  }
  toggleDisclosure();
}

function onKeydown(event: KeyboardEvent) {
  if (disabled) {
    return;
  }

  switch (event.key) {
    case Keys.Space:
    case Keys.Enter:
      event.preventDefault();
      event.stopPropagation();
      toggleDisclosure();
      break;
    default:
      break;
  }
}

onMounted(() => {
  context.buttonId.value = id;
});

onUnmounted(() => {
  context.buttonId.value = null;
});

if (!withinPanel.value) {
  watchEffect(() => {
    context.buttonRef.value = elementRef.value;
  });
}

const slots = useSlots();

defineRender(() => {
  return slots.default?.({
    rootProps: {
      'aria-controls': context.panelId?.value,
      'aria-expanded': disabled ? undefined : context.expanded.value,
      onClick,
      onKeydown,
      id,
      ref: (el: Element | ComponentPublicInstance | null) => {
        elementRef.value = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
      },
    },
  });
});
</script>
