import { Meilisearch as MeilisearchClient, type SearchResponse } from 'meilisearch';
import { jwtDecode } from 'jwt-decode';
import { Auth } from './auth';
import { Transport } from './transport';

interface MeilisearchTokenResponse {
  token: string;
}

interface MeilisearchOptions {
  host: string;
  tokenEndpoint: string;
  transport: Transport;
  auth: Auth;
}

export enum MeilisearchIndexes {
  Location = 'Location',
  Sku = 'Sku',
  Inventory = 'Inventory',
  Replenishment = 'Replenishment',
  ReplenishmentInventory = 'Inventory-Replenishment',
  SpecialEvent = 'SpecialEvent',
  SpecialEventInventory = 'SpecialEventInventory',
  Department = 'Department',
  Region = 'Region',
  City = 'City',
  Brand = 'Brand',
  Classification = 'Classification',
  Season = 'Season',
  Size = 'Size',
  Style = 'Style',
  Category = 'Category',
  Market = 'Market',
  Color = 'Color',
  Product = 'Product',
  AllocationProductLocation = 'AllocationProductLocation',
  AllocationInventory = 'AllocationInventory',
  SkuTrip = 'SkuTrip',
}

export type MeilisearchResponse<T = Record<string, any>> = Pick<
  SearchResponse<T>,
  'hits' | 'offset' | 'limit' | 'estimatedTotalHits'
>;

export class Meilisearch {
  protected readonly host: string;
  protected readonly tokenEndpoint: string;
  protected readonly transport: Transport;
  protected readonly auth: Auth;

  protected tokenExpiresAt = 0;
  protected minTokenValidity = 1000 * 30;
  protected clientPromise?: Promise<MeilisearchClient>;

  protected client?: MeilisearchClient;

  constructor(options: MeilisearchOptions) {
    this.host = options.host;
    this.tokenEndpoint = options.tokenEndpoint;
    this.transport = options.transport;
    this.auth = options.auth;

    this.auth.onStateChanged(({ authenticated }) => {
      if (!authenticated) {
        this.client = undefined;
        this.tokenExpiresAt = 0;
      }
    });
  }

  async getClient(): Promise<MeilisearchClient> {
    if (this.clientPromise) {
      return this.clientPromise;
    }

    if (this.client && this.tokenExpiresAt - Date.now() > this.minTokenValidity) {
      return this.client;
    }

    this.clientPromise = this.createClient();

    this.clientPromise.then(() => {
      this.clientPromise = undefined;
    });

    return this.clientPromise;
  }

  protected async getToken(): Promise<string> {
    const { data } = await this.transport.post<MeilisearchTokenResponse>(this.tokenEndpoint, {});

    return data.token;
  }

  protected async createClient(): Promise<MeilisearchClient> {
    const token = await this.getToken();

    this.client = new MeilisearchClient({
      host: this.host,
      apiKey: token,
    });

    const { exp } = jwtDecode<{ exp: number }>(token);
    this.tokenExpiresAt = exp * 1000;

    return this.client;
  }
}
